<template>
  <el-row class="row-center">
    <el-col class="col">
      <Title2
        :title="'CITA DE ESTUDIOS DE GABINETE'"
        :label="'Por favor ingrese los datos para agendar una cita'"
        :btnText="'DAR DE ALTA'"
        v-on:before-submit="beforeSubmit"/>
      <div class="profile row">
        <div class="left">
          <el-container>
            <el-main>
              <p style="color:#006EFF;font-weight:bold;font-size:15px!important;margin:0px;">
                DATOS GENERALES
              </p>
              <el-form
                :model="form"
                ref="refId"
                :rules="rules"
                @keyup.enter.native="beforeSubmit()"
                @submit.prevent>
                <div class="row">
                  <el-form-item prop="firstName" label="Nombres">
                    <el-input v-model="form.firstName" :disabled="true" />
                  </el-form-item>
                  <el-form-item
                    prop="lastName"
                    label="Apellidos"
                    style="padding-left:5%;padding-right:0%!important;">
                    <el-input v-model="form.lastName" :disabled="true" />
                  </el-form-item>
                </div>
                <div class="row">
                  <el-form-item
                    prop="birthdate"
                    label="Fecha de nacimiento (aaaa-mm-dd)">
                    <el-input v-model="form.birthdate" :disabled="true" />
                  </el-form-item>
                  <el-form-item
                    prop="curp"
                    style="padding-left:5%;padding-right:0%!important;"
                    label="CURP">
                    <el-input
                      v-model="form.curp"
                      @input="upper($event)"
                      :disabled="true" />
                  </el-form-item>
                </div>
                <div class="row">
                  <el-form-item prop="phone" label="Teléfono Fijo">
                    <el-input v-model="form.phone" :disabled="disabled"/>
                  </el-form-item>
                  <el-form-item
                    prop="cellPhone"
                    label="Teléfono Particular"
                    style="padding-left:5%;padding-right:0%!important;">
                    <el-input v-model="form.cellPhone" :disabled="disabled"/>
                  </el-form-item>
                </div>
                <div class="row">
                  <el-form-item prop="extraPhone" label="Teléfono Extra">
                    <el-input v-model="form.extraPhone" disabled="disabled" />
                  </el-form-item>
                </div>
                <div class="row">
                  <div
                    class="el-form-item is-required"
                    style="padding-right:0%!important;"
                    v-show="false"
                  >
                    <label class="el-form-item__label" style="width: 100%;text-align:left">
                      Área
                    </label>
                    <div class="el-form-item__content">
                      <el-select
                        v-model="selectedRole"
                        :default-first-option="true"
                      >
                        <el-option
                          v-for="role in roles"
                          :key="role.id"
                          :label="role.name"
                          :value="role.id"
                        />
                      </el-select>
                    </div>
                  </div>
                  <div style="margin: 25px 0 25px 0;width: 40%!important;">
                    <p style="color:#006EFF;font-weight:bold;font-size:15px!important;margin:0px;">
                      DATOS DE CITA
                    </p>
                    <div style="margin-left:10px; width:max-content;">
                      <h4 style="color:#49749F; font-size: 12px">Estudios Gabinete</h4>
                      <el-button
                          style="
                            background: #49749F;
                            color: #fff;
                            margin-bottom: 15px;
                          "
                          icon="el-icon-plus"
                          size="medium"
                          circle
                          @click="AgregarStudio()"
                        />
                      <div
                        class="estudio-costo"
                        v-for="(item, idx) in nuevosEstudios"
                        :key="item.id"
                      >
                        <div>
                        <strong>Estudio</strong>
                          <el-form-item
                            prop="estudiosValue"
                            style="
                            margin-top: 10px;
                            width: 80%;
                            "
                            size="mini"
                          >
                            <el-select
                              v-model="nuevosEstudios[idx].gabineteId"
                              filterable
                            >
                              <el-option
                                v-for="item in estudios"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                              />
                            </el-select>
                          </el-form-item>
                        <div style="">
                          <strong>Comentario</strong>
                          <el-input
                            type="text"
                            v-model="nuevosEstudios[idx].comments"
                            style=""
                          />
                        </div>
                        <el-divider />
                        </div>
                        <div style="margin:0 18px 0 10px;">
                          <strong>Fecha</strong>
                          <p>{{nuevosEstudios[idx].date}}</p>
                        </div>
                        <h4>{{nuevosEstudios[idx].id}}</h4>
                        <div style="margin:0 18px 0 10px;">
                          <strong>Hora</strong>
                          <p>{{nuevosEstudios[idx].time}}</p>
                        </div>
                        <div>
                          <el-button
                            type="danger"
                            style="
                              color: #fff;
                              font-size: 8px;
                            "
                            icon="el-icon-minus"
                            size="mini"
                            circle
                            @click="EliminarStudio(idx)"
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                    <el-drawer
                      :before-close="handleClose"
                      :visible.sync="dialog"
                      direction="ltr"
                      custom-class="demo-drawer"
                      ref="drawer"
                      >
                      <div>
                        <el-form :model="form">
                        <div
                          style="
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            flex-wrap: wrap;
                            margin-top: 20px;
                            margin-right: 80px;
                          "
                        >
                          <el-form-item label="Costo">
                            <el-input v-model="form.name" autocomplete="off"/>
                          </el-form-item>
                          <el-form-item label="Área" >
                            <el-select v-model="form.region" placeholder="Please select activity">
                              <el-option label="Area1" value="shanghai" />
                              <el-option label="Area2" value="beijing" />
                            </el-select>
                          </el-form-item>
                        </div>
                        </el-form>
                        <div
                          style="
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            flex-wrap: wrap;
                            align-content: center;
                            margin-top: 20px;
                            margin-right: 40px;
                          "
                        >
                          <el-button @click="cancelForm">Cancelar</el-button>
                          <el-button
                            type="primary"
                            @click="
                            $refs.drawer.closeDrawer()
                            " :loading="loading">{{ loading ? 'Submitting ...' : 'Agendar' }}
                          </el-button>
                        </div>
                      </div>
                    </el-drawer>
                </div>
                <el-form-item style="width: 100%!important;margin-bottom: 0px!important">
                  <el-button
                    type="primary"
                    @click="beforeSubmit"
                    style="background-color: #6993FF;color:#fff;float:right;">
                    {{btnText}}
                  </el-button>
                </el-form-item>
              </el-form>
            </el-main>
          </el-container>
        </div>
        <div :class="right">
          <Calendar
            v-on:selected-hour="selectedHour"
            v-on:selected-date="selectedDate"
            :disabledsEspeciality="reservedHoursProcedure"
          />
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import Title2 from '../../../components/Title2';
import Calendar from '../../../components/Calendar';

export default {
  data() {
    return {
      // languages: lang,
      comentarios: null,
      procedimiento: null,
      dialog: false,
      drawer: false,
      procedure: '',
      citaHora: [],
      time: null,
      date: null,
      nuevosEstudios: [],
      nuevosEstudiosCC: [],
      roles: [{ id: 'MD', name: 'MÉDICO' }],
      users: [],
      reservedHoursProcedure: [],
      selectedRole: 'MD',
      right: 'right',
      btnText: 'AGENDAR',
      title: 'CITA SUBSECUENTE',
      label: 'Por favor llene los datos para agendar una cita nueva',
      hideButton: false,
      disabled: true,
      language: 'es',
      loading: false,
      loadingService: null,
      initialForm: {},
      form: {
        userId: null,
        firstName: '',
        lsatName: '',
        birthdate: '',
        curp: '',
        phone: '',
        extraPhone: '',
        cellPhone: '',
        comments: '',
      },
      estudios: [
        {
          id: 1,
          name: 'CV',
          value: 'CV',
        },
        {
          id: 2,
          name: 'OCT',
          value: 'OCT',
        },
        {
          id: 3,
          name: 'FAG',
          value: 'FAG',
        },
        {
          id: 4,
          name: 'FOTOS',
          value: 'FOTOS',
        },
        {
          id: 5,
          name: 'PAQUIS',
          value: 'PAQUIS',
        },
        {
          id: 6,
          name: 'IOL',
          value: 'IOL',
        },
        {
          id: 7,
          name: 'VISITANTE',
          value: 'VISITANTE',
        },
        {
          id: 8,
          name: 'ORBSCAN',
          value: 'ORBSCAN',
        },
        {
          id: 9,
          name: 'OPD',
          value: 'OPD',
        },
        {
          id: 10,
          name: 'PENTACAM',
          value: 'PENTACAM',
        },
        {
          id: 11,
          name: 'ATLAS',
          value: 'ATLAS',
        },
        {
          id: 12,
          name: 'CONT/CELULAS',
          value: 'CONT/CELULAS',
        },
        {
          id: 13,
          name: 'INMERSION',
          value: 'INMERSION',
        },
        {
          id: 14,
          name: 'PAM',
          value: 'PAM',
        },
        {
          id: 15,
          name: 'NIDEK',
          value: 'NIDEK',
        },
      ],
      valuEstudiosCC: [
        {
          name: '',
        },
      ],
      valuEstudiosSC: [],
      socialSchedule: false,
      formLabelWidth: '80px',
      timer: null,
      newSocialDate: [
        {
          value: true,
          label: 'SI',
        },
        {
          value: false,
          label: 'NO',
        },
      ],
      rules: {
        userId: [
          {
            required: true,
            message: 'El campo no puede estar vacío.',
            trigger: 'blur',
          },
          {
            type: 'number',
            message: 'Formato no válido',
            trigger: 'onchange',
          },
        ],
        firstName: [
          {
            required: true,
            message: 'El campo es requerido',
            trigger: 'blur',
          },
        ],
        lastName: [
          {
            required: true,
            message: 'El campo es requerido',
            trigger: 'blur',
          },
        ],
        birthdate: [
          {
            required: true,
            message: 'El campo es requerido',
            trigger: 'blur',
          },
        ],
        comments: [
          {
            required: false,
          },
        ],
      },
    };
  },
  components: {
    Title2,
    Calendar,
    // Datepicker,
  },
  computed: {
    userId() {
      return this.form.userId;
    },
    especialistaId() {
      return this.procedimiento;
    },
  },
  methods: {
    AgregarStudio() {
      this.procedure = null;
      this.time = null;
      if (this.procedure === '' && this.nuevosEstudios.length >= 1) {
        const n = this.nuevosEstudios.length - 1;
        if (this.nuevosEstudios[n].procedure === '') {
          window.alert('Elija Un Estudio');
          return;
        }
      }
      // Get form vars
      const {
        userId,
      } = this.form;
      const type = 'CITA GABINETE';
      const info = {
        procedure: null,
        time: this.time,
        date: this.date,
        type,
        location: null,
        specialityId: null,
        subject: 'Gabinete',
        userId,
        comments: this.comentarios,
        gabineteId: this.procedure,
      };
      this.nuevosEstudiosCC.push(info);
      this.nuevosEstudios = this.nuevosEstudiosCC;
      this.procedure = null;
      this.time = null;
    },
    EliminarStudio(index) {
      this.nuevosEstudios.splice(index, 1);
    },
    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm('Do you want to submit?')
        .then(() => {
          this.loading = true;
          this.timer = setTimeout(() => {
            done();
            // animation takes time
            setTimeout(() => {
              this.loading = false;
            }, 400);
          }, 700);
        })
        .catch(() => {});
    },
    cancelForm() {
      this.loading = false;
      this.dialog = false;
      clearTimeout(this.timer);
    },
    async selectedHour(value) {
      this.time = value;
      this.ultimo = this.nuevosEstudios.length - 1;
      this.nuevosEstudios[this.ultimo].time = value;
    },
    selectedDate(value) {
      this.date = value;
      this.ultimo = this.nuevosEstudios.length - 1;
      this.nuevosEstudios[this.ultimo].date = value;
    },
    beforeSubmit() {
      this.$refs.refId.validate((isValid) => {
        if (isValid && this.time) {
          this.submit();
        } else {
          this.$message('Verifique las fechas en el calendario');
        }
      });
    },
    async submit() {
      try {
        this.loading = true;
        const { id: patientId } = this.$route.params;
        const data = this.nuevosEstudios;
        const requests = [];
        requests.concat(data.map((item) => this.axios.post(`patient/subsecuente/gabinete/${patientId}`, {
          ...item,
        })));
        if (requests) {
          const message = 'Se agendó cita subsecuente';
          await this.axios.post(`patient/changelog/${patientId}`, {
            title: 'Agenda',
            message,
          });
          this.$notify({
            type: 'success',
            message,
          });
          this.loading = false;
          let path = '/';
          if (this.socialSchedule && patientId) {
            path = `/admin/appointments/social/${patientId}`;
          }
          setTimeout(() => {
            this.$router.replace({ path });
          });
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$errorNotify(error);
      }
    },
    async getData() {
      try {
        this.loading = true;
        const { id: patientId } = this.$route.params;
        const response = await this.axios.get(`patient/info/${patientId}`);
        this.form = {
          ...this.form,
          ...response.data,
        };
        this.initialForm = JSON.parse(JSON.stringify(this.form));
        this.loading = false;
      } catch (error) {
        // do something
        this.loading = false;
      }
    },
    async getUsers() {
      try {
        this.loading = true;
        const response = await this.axios.get(`patient/appointment/users/${this.selectedRole}`);
        const { data } = response;
        if (Array.isArray(data)) {
          this.users = data;
        }
        this.loading = false;
      } catch (error) {
        this.$errorNotify(error);
        this.loading = false;
      }
    },
    async getReservedHoursProcedure() {
      try {
        this.loading = true;
        if (this.date) {
          const response = await this.axios.get(`patient/subsecuente/esp/reserved-schedules-gabinete/${this.procedimiento}?date=${this.date}`);
          const { data } = response;
          this.cantidadCitas = data;
          for (let index = 0; index < data.length; index++) {
            this.citaHora[index] = data[index].time;
          }
          if (Array.isArray(data) && data.length > 0) {
            this.reservedHoursProcedure = data;
            const prereserved = [];
            for (let index = 0; index < data.length; index++) {
              // this.reservedHoursProcedure[index] = data[index].time;
              const elemento = data[index].time;
              prereserved[elemento] = prereserved[elemento] ? prereserved[elemento] + 1 : 1;
            }
          }
        }
        this.loading = false;
      } catch (error) {
        this.$errorNotify(error);
        this.loading = false;
      }
    },
  },
  created() {
    this.getData();
    if (this.selectedRole) {
      this.getUsers();
    }
  },
  watch: {
    loading(val) {
      if (val && !this.loadingService) {
        this.loadingService = this.$loading({
          fullscreen: true,
          lock: true,
          text: 'Cargando',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
        });
      } else if (this.loadingService) {
        this.loadingService.close();
        this.loadingService = null;
      }
    },
    selectedRole(val) {
      if (val) {
        this.form.userId = null;
        this.reservedHoursProcedure = [];
        this.getReservedHoursProcedure();
        this.getUsers();
      }
    },
    userId(val) {
      if (val) {
        this.reservedHoursProcedure = [];
        this.getReservedHoursProcedure();
      }
    },
    date(val) {
      if (val) {
        this.reservedHoursProcedure = [];
        this.getReservedHoursProcedure();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
div.disabled
{
  pointer-events: none;
  user-select: none;
  opacity: 0.75;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background: #C2BEBF;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #B2AEAA;
}
.row {
  display: flex;
  flex-direction: row;
}
.row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.el-row {
  height: 100%;
  .el-col {
    width:95%;
    height: 98%;
    .profile {
      padding-top: 10px;
      height: 90%;
      width: 100%;
      .left {
        width: 70%;
        .el-container {
          background-color: #FFF!important;
          height: 100%!important;
          min-height: 0%;
          .el-main {
            padding: 10px 25px;
            margin: 0px;
            .el-form {
              .el-form-item {
                width: 50%;
                margin-bottom: 12px!important;
                .el-form-item__content {
                  width: 90%!important;
                  .el-input {
                    width: 100%!important;
                  }
                }
              }
            }
          }
        }
      }
      .right {
        height: 100%;
        width: 30%;
        background-color: #fff;
        overflow-y: auto;
      }
    }
  }
}
.estudio-costo {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 20px;
}
</style>
